import {ModelXhr} from '../helpers/model/xhr.js';

class ModelEtherscan extends ModelXhr {
    static modelName = 'ModelEtherscan';

    static getSelect(input){
        return input;
    }

    constructor(selectData, options = {}){
        super(selectData, {
            endpoint: options.apiEndpoint || window.etherscanApiEndpoints[selectData.chainId || 1],
            method: 'GET'
        });

        this.apiKey = options.apiKey || window.etherscanApiKeys[selectData.chainId || 1];
        // disable to support cross domain request
        this.useXMLHttpRequest = false;
    }

    fetchData(){
        // Only trigger request when module is set.
        clearTimeout(this.timerId);
        if(this.select?.module){
            super.fetchData();
        }

        this.trigger();
    }

    onSuccess(dataRaw){
        super.onSuccess(dataRaw);
    }

    getSelect(input){
        const payload = ModelEtherscan.getSelect(input);
        return {...payload, apikey: this.apiKey};
    }

    getPayload(select){
        /* eslint-disable-next-line */
        const {chainId, ...payload} = select;
        return payload;
    }

    getDataFromResponse(data){
        try {
            const response = JSON.parse(data);
            if(response.status && response.status === '1' && response.hasOwnProperty('result')){
                return response.result;
            }else{
                this.onFailure(response?.message || 'Unknown error');
            }
        } catch (e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' +
                        e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }
}

export {ModelEtherscan};
