import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {NavigationBack} from '../../components/navigation/back.js';
import {ViewGridRow} from '../../view/grid/row.js';
import {ViewPanel} from '../../view/panel/panel.js';
import {FeedbackError} from '../../components/feedback/error.js';
import {UIInputItem} from '../../components/ui/input/item.js';
import {UIInput} from '../../components/ui/input/input.js';
import {UIInputSelect} from '../../components/ui/input/select.js';
import {UIButtonConfirm} from '../../components/ui/button/confirm.js';
import {useModel} from '../../helpers/model/use_model.js';
import {useStateContext} from '../../helpers/state-context.js';
import {CONFIG} from '../../config.js';
import {ModelSgtsJs} from '../../model/sgts-js.js';
import {useUser} from '../../model/use_user.js';

const UIInputWithWarning = function(props){
    return <>
        <UIInput {...props}/>
        <span className="warning">
            Make sure that the wallet address of the creditor is correct. Once funds are
            transferred to an external wallet it is <strong>impossible to revert</strong> the
            transaction.
        </span>
    </>;
};

function CreditorsEdit(props){
    const user = useUser();
    const [, dispatch] = useStateContext(['action']);
    const [formValues, setFormValues] = useState({
        wallet: '',
        chainId: '',
        buName: '',
        description: ''
    });

    const deleteCreditor = async (wallet, chainId, buName) => {
        // delete creditor and redirect back to list
        try {
            await globalThis.PayeeManagementApi.deletePayee(wallet, parseInt(chainId), buName);
            // status is success when at this point
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
        } catch (resultSetError){
            // error handling (for some reason this sometimes get triggered twice, second time we get an
            // error. Lets not bother displaying that to the user)
            console.log('Failed to delete creditor. ' + resultSetError.error);
        }
        dispatch({type: 'setValues', value: {action: null, id: null}});
    };
    useEffect(() => {
        // TODO: Sometimes this seems to be triggered twice. Not sure why.
        if(props.id && props.type === 'delete'){
            const [wallet, chainId, buName] = props.id.split('||');
            deleteCreditor(wallet, chainId, buName);
        }
    }, [props.id, props.type]);

    const [error, setError] = useState(null);
    const selectionCriteria = user?.isSGTS ? {method: 'BuManagementApi.listBusinessUnits'} : {};
    const resultSet = useModel(ModelSgtsJs, selectionCriteria);
    const units = user?.isSGTS && resultSet.status === ModelSgtsJs.Status.SUCCESS ?
            resultSet.data.map((item) => (item.name)) :
            user?.isSGTS ? [] : (user?.unit ? [user.unit] : []);

    useEffect(() => {
        // If we have a BU user, we set the unit the user belongs to.
        if(user && ! user?.isSGTS && formValues.buName !== user.unit){
            setFormValues((prev) => {return {...prev, buName: user.unit};});
        }
    }, [user]);

    const saveCreditor = async () => {
        if(! formValues.description){
            setError('Please provide a name for the creditor');
            return;
        }else if(! formValues.wallet){
            setError('Please provide the wallet address of the creditor');
            return;
        }else if(! /^0x[a-fA-F0-9]{40}$/.test(formValues.wallet)){
            setError('Please provide a valid address using Ethereum format');
            return;
        }else if(! formValues.chainId){
            setError('Please provide the blockchain id of the creditor');
            return;
        }else if(isNaN(formValues.chainId)){
            setError('Blockchain id is not a number');
            return;
        }else if(! formValues.buName){
            setError('Please select a business unit for the creditor');
            return;
        }else if(isNaN(formValues.chainId)){
            setError('Blockchain id is not a number');
            return;
        }
        // TODO: we currently don't have a way to edit an existing payee, so we only have logic here for
        // creating a new one
        try {
            const result = await globalThis.PayeeManagementApi.inputPayee(
                    formValues.wallet, parseInt(formValues.chainId, 10), formValues.buName,
                    formValues.description);
            const id = [result.address, result.chainId, result.bu].join('||');
            ModelSgtsJs.invalidateAllInstances(ModelSgtsJs);
            dispatch({type: 'setValues', value: {action: null, id}});
        } catch (err){
            // error handling
            setError('Failed create creditor. ' + err);
        }
    };

    return <ViewGridRow className="page-row">
        <NavigationBack payload={{id: null, action: null}}/>
        <h1>{props.type === 'edit' ? 'Edit creditor' : 'New creditor'}</h1>
        <ViewPanel>
            <FeedbackError error={error}/>

            <UIInputItem Component={UIInput} name="description" value={formValues.description}
                    label="Creditor name"
                    onChange={(newValue) => {setFormValues({...formValues, description: newValue});}}/>

            <UIInputItem Component={UIInputWithWarning} name="wallet" value={formValues.wallet}
                    label="Wallet address"
                    onChange={(newValue) => {setFormValues({...formValues, wallet: newValue});}}/>

            <UIInputItem Component={UIInputSelect} name="chainId" value={formValues.chainId}
                    label="Blockchain id" options={CONFIG.blockchainIds}
                    onChange={(newValue) => {setFormValues({...formValues, chainId: newValue});}}/>

            {/* At the moment sgts users cannot create creditors, but if this changes, they would need to
                select a BU */}
            {user?.isSGTS ?
                <UIInputItem Component={UIInputSelect} name="buName" value={formValues.buName}
                        label="Business unit" options={units}
                        onChange={(newValue) => {setFormValues({...formValues, buName: newValue});}}/> :
                null
            }

            <UIButtonConfirm className="btn-submit" onClick={saveCreditor}>
                Create creditor
            </UIButtonConfirm>
        </ViewPanel>
    </ViewGridRow>;
}

CreditorsEdit.propTypes = {
    type: PropTypes.oneOf(['new', 'edit', 'delete']),
    id: PropTypes.string
};

CreditorsEdit.defaultProps = {
    type: 'new'
};

export {CreditorsEdit};
